<template>
  <Layout>
    <div class="row mb-5">
      <div class="col-sm-12 col-lg-6 offset-lg-3 registration-right pb-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="registration-form">
              <p class="welcome mb-4">{{ $t("welcome") }}</p>

              <div style="padding-left: 6%; padding-right: 6%">
                <form class="needs-validation" @submit.prevent="tryTologin">
                  <!--<b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible>Logged in Successfully.</b-alert>-->
                  <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}</b-alert>
                  <div v-if="notification.message" :class="'alert ' + notification.type">{{
                      notification.message
                    }}
                  </div>
                  <div>
                    <label for="email" class="form-label">{{ $t("email") }}</label>
                    <div class="form-icon right">
                      <input type="email" class="form-control form-control-icon" id="email"
                             v-model="user.email"
                             :class="{'is-invalid': submitted && v$.user.email.$error,}"
                             :placeholder="$t('type_in')">
                      <i class="ri-mail-line red-color"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.email.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>

                  <div class="mt-4">
                    <label for="password" class="form-label">{{ $t("password") }}</label>
                    <div class="form-icon right">
                      <input ref="password" :type="isSeenPassword? 'password' : 'text'"
                             class="form-control form-control-icon" id="password"
                             v-model="user.password"
                             :class="{'is-invalid': submitted && v$.user.password.$error,}"
                             :placeholder="$t('type_in')">
                      <i @click="OnClickedPasswordVisibilityChange" class="red-color cursor-pointer"
                         :class="isSeenPassword? 'ri-eye-line': 'ri-eye-off-line'"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.password.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                  <div class="mt-3 d-grid gap-2">
                    <button class="submit">{{ $t("login") }}</button>
                  </div>
                </form>
                <div class="d-flex justify-content-between link-sign-in text-center mt-3">
                  <RouterLink to="/forgot-password">
                    <span class="red-color">{{ $t("forgot_password") }}</span>
                  </RouterLink>
                  <RouterLink to="/signup">
                    <span class="existing-user text-black">{{ $t("new_user") }} </span>
                    <span class="red-color">&nbsp;{{ $t("sign_up") }}</span>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 100px"></div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import appConfig from "../../../../app.config.json";

import {
  required,
  email,
  helpers,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {
  authMethods,
} from "@/state/helpers";

export default {
  page: {
    title: "Login",
    meta: [{
      name: "description",
      content: appConfig.description,
    }],
  },
  name: "login",
  components: {
    Layout,
    // GoogleBtn,
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      isSeenPassword: true,
      user: {
        email: "",
        password: ""
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
    };
  },
  validations() {
    return {
      user: {
        email: {
          required: helpers.withMessage("Email is required", required),
          email: helpers.withMessage("Please enter valid email", email),
        },
        password: {
          required: helpers.withMessage("Password is required", required),
        },
      },
    }
  },
  mounted() {
    document.documentElement.setAttribute("data-layout", "horizontal");
    document.documentElement.setAttribute("data-layout-width", "fluid");
    document.documentElement.setAttribute("data-topbar", "dark");
    document.documentElement.setAttribute("data-sidebar-size", "sm");
    document.documentElement.setAttribute("data-layout-position", "fixed"); //scrollable
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authMethods,
    async tryTologin() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        this.tryingToRegister = true;
        // Reset the regError if it existed.
        this.regError = null;
        this.isRegisterError = false;
        this.$isLoading(true); // show loading screen
        await this.logIn({
          email: this.user.email,
          password: this.user.password,
        })
            .then((response) => {
              const {status, message, user} = response;
              if (status === "success") {
                this.tryingToRegister = false;
                this.isRegisterError = false;
                this.registerSuccess = true;
                localStorage.setItem('jwt', user.jwt)
                this.$router.push({
                  path: localStorage.getItem("redirect") ?? "/",
                });
              } else {
                this.regError = message;
                this.tryingToRegister = false;
                this.isRegisterError = true;
              }
            })
            .catch((error) => {
              this.tryingToRegister = false;
              this.regError = error ? error : "";
              this.isRegisterError = true;
            })
            .finally(() => {
              this.$isLoading(false);
            })
      }
    },
    OnClickedPasswordVisibilityChange() {
      this.isSeenPassword = !this.isSeenPassword;
      this.$refs.password.focus();
    },
  },
  'watch': {
    '$route': {
      handler: function () {

      },
    }
  },
  beforeCreate() {
    if (this.$route.query._token) {
      localStorage.setItem('jwt', this.$route.query._token)
      this.$router.push({
        path: '/'
      });
    }
  }
}
</script>
<style scoped></style>